import React from 'react';
import { View, Dimensions, ImageBackground, Text, TouchableOpacity, ScrollView, Image } from 'react-native';
import moment from 'moment';
import './App.css';
import feathersLib from './feathersLib';
import Modal from 'modal-enhanced-react-native-web';
import { TouchableHighlight } from 'react-native-web';
import { PieChart } from 'react-minimal-pie-chart';
import * as Animatable from 'react-native-animatable';
import Lib from './Lib';

const DATA = require('./Data');
const PD = DATA.productData;
const ND = DATA.nutriData;
const getNutriData = DATA.getNutriData;

const LOGO = require('./img/logo.png');
const STAR = require('./img/star.png');
const SHADOW = require('./img/shadow.png');
const BENTOBOX = require('./img/mealbox.png');
const BENTOOPEN = require('./img/bentoopen.gif');
const CLOSE = require('./img/close.png');
const CLOSEWHITE = require('./img/closewhite.png');
const DOWN = require('./img/down.png');
const GREENER1 = require('./img/greener1.gif');
const GREENER2 = require('./img/greener2.gif');
const GREENER3 = require('./img/greener3.gif');
const BACK = require('./img/back.png');

const IMG = {};
IMG['redveinedsorrel.png'] = require('./img/redveinedsorrel.jpg');
IMG['bokchoy.png'] = require('./img/bokchoy.jpg');
IMG['greenradish.png'] = require('./img/greenradish.jpg');
IMG['redradish.png'] = require('./img/redradish.jpg');
IMG['kale.png'] = require('./img/kale.jpg');
IMG['parsley.png'] = require('./img/parsley.jpg');
IMG['coriander.png'] = require('./img/coriander.jpg');
IMG['basil.png'] = require('./img/basil.jpg');
IMG['greenamaranth.png'] = require('./img/greenamaranth.png');
IMG['brocolli.png'] = require('./img/brocolli.jpg');
IMG['redcabbage.png'] = require('./img/redcabbage.jpg');
IMG['wheatgrass.png'] = require('./img/wheatgrass.png');
IMG['peashoot.png'] = require('./img/peashoot.jpg');
IMG['redamaranth.png'] = require('./img/redamaranth.jpg');

const DONUTS = {};
DONUTS['SORREL ROASTED SALMON'] = require('./img/sorrelroastedsalmon.png');
DONUTS['CORIANDER CHICKEN RICE'] = require('./img/corianderchickenrice.png');
DONUTS['CHICKEN MARINARA'] = require('./img/chickenmarinara.png');
DONUTS['LOCO MOCO'] = require('./img/locomoco.png');
DONUTS['GREENS NUGGETS'] = require('./img/powernuggets.png');
DONUTS['SUPREME SALMON TERIYAKI'] = require('./img/supremesalmonteriyaki.png');

const BG = {};
BG['SORREL ROASTED SALMON'] = require('./img/bg1.png');
BG['CORIANDER CHICKEN RICE'] = require('./img/bg2.png');
BG['CHICKEN MARINARA'] = require('./img/bg3.png');
BG['LOCO MOCO'] = require('./img/bg4.png');
BG['POWER NUGGETS'] = require('./img/bg5.png');

function overrideTitle(name) {
  if (name === 'CORIANDER CHICKEN RICE') return 'MIGHTY CORIANDER CHICKEN RICE';
  if (name === 'CHICKEN MARINARA') return 'GREENS PASTA WITH CHICKEN MARINARA';
  return name;
}

let globalMultiplierWidth = 1;
let globalMultiplierHeight = 1;
function PW(num) {
  return Math.round(num * globalMultiplierWidth);
}

function PH(num) {
  return Math.round(num * globalMultiplierHeight);
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: 'front'
    };
  }

  async componentDidMount() {
    try {
      const ww = Dimensions.get('window').width;
      const wh = Dimensions.get('window').height;
      const landscape = (ww > wh);
      let sw = ww;
      let sh = wh;
      if (landscape) {
        sh = 0.9 * wh;
        sw = 360 * sh / 646;
      }
      let mulW = sw / 360;
      let mulH = sh / 646;
      globalMultiplierWidth = mulW;
      globalMultiplierHeight = mulH;

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      // let code = '6189af615673333ef1fa6ef0'; // urlParams.get('code');
      let code = urlParams.get('code');
      const id = urlParams.get('id');
      if (id === 'error') {
        // return this.setState({ show: 'error', ww, wh });
        code = '61ac79245a15a83fb217bbb0';
      }
      if (code) {
        await feathersLib.loginByEmailPassword('leo@greens.id', '123123123');
        const data = await feathersLib.getData(code);
        this.setState({ code, data });
      } else {
        return this.setState({ show: 'warning', ww, wh });
      }

      this.setState({ show: 'front', ww, wh, landscape, sh, sw, mulW, mulH });

      // await Lib.delay(2000);
      // this.setState({ animDone: true });
    } catch (err) {
      console.error(err);
    }
  }

  componentWillMount() {
  }

  toggleNutritionFact(menuTitle) {
    this.setState({ nfVisible: true, menuSelected: menuTitle.toUpperCase() });
  }

  toggleProduct(item) {
    this.setState({ pVisible: true, selectedProduct: item });
  }

  renderNF() {
    const data = this.state.data;
    const menuTitle = data.title;
    const menuSubtitle = 'Resep dari ' + data.creatorName;
    let menuDesc = data.description;

    const sw = this.state.sw * 0.8;
    const sh = this.state.sh - PH(50);
    const ts1 = { color: '#5BA545', fontWeight: 700, fontSize: PW(12), textAlign: 'center' };
    const ts2 = { color: '#808080', fontWeight: 700, fontSize: PW(16) };
    const ts3 = { color: '#808080', fontWeight: 600, fontSize: PW(10), textAlign: 'center', zIndex: 2 };
    const ts4 = { color: '#808080', fontWeight: 600, fontSize: PW(12) };

    const subtitleStyle = { color: '#036A3A', textAlign: 'center', fontWeight: 500, marginTop: 5, fontStyle: 'italic' };
    const descStyle = { color: '#808080', textAlign: 'center' };
    const discStyle = { color: '#808080', textAlign: 'left', fontSize: PW(10) };

    let nd = getNutriData(data);
    if (!nd) nd = ND[menuTitle];
    const disclaimer = 'Persentasi AKG (Angka Kecukupan Gizi ) berdasarkan pada kebutuhan energi 2000kkal. Kebutuhan energi harian anda mungkin lebih tinggi atau lebih rendah.';

    let content = <View />;
    if (nd) {
      if (nd.description) menuDesc = nd.description;
      const total = nd.total;
      const rows = nd.top;
      const rows2 = nd.bottom;
      const uri = DONUTS[menuTitle];
      const donutData = [];
      for (let i = 0; i < rows.length; i++) {
        const dd = rows[i];
        donutData.push({ title: dd.label, value: dd.pctg, color: dd.color });
      }
      content = (
        <View style={{ paddingHorizontal: 0 }}>
          <View style={{ alignItems: 'center', justifyContent: 'center', paddingVertical: PH(26) }}>
            {/* <ImageBackground source={{ uri: uri }} resizeMode='stretch' style={{ width: PW(96), height: PH(96), alignItems: 'center', justifyContent: 'center' }}>
            </ImageBackground> */}
            <View style={{ width: PW(96), height: PW(96) }}>
              <PieChart
                lineWidth={50}
                data={donutData}
              />
              <View style={{ position: 'absolute', width: PW(96), height: PW(96), alignItems: 'center', justifyContent: 'center' }}>
                <Text style={ts2}>{total}</Text>
                <Text style={ts3}>KCAL</Text>
              </View>
            </View>
            <Text> </Text>
            <Text style={ts2}>Energi Dikonsumsi</Text>
          </View>
          <View>
            {rows.map((item, index) => {
              let fullWidth = PW(165);
              let partWidth = item.pctg * fullWidth / 100;
              let hh = PH(16);
              let col = item.color;
              return (
                <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10 }}>
                  <Text style={ts4}>{item.label}</Text>
                  <View style={{ width: fullWidth, height: hh, backgroundColor: 'gainsboro' }}>
                    <View style={{ width: partWidth, height: hh, backgroundColor: col }}></View>
                    <View style={{ width: fullWidth, height: hh, position: 'absolute', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={ts3}>{item.text}</Text>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
          <Text> </Text>
          <View style={{ height: 1, backgroundColor: 'gainsboro' }} />
          <Text> </Text>
          <View>
            {rows2.map((item, index) => {
              if (!item.pctg) return null;
              return (
                <View key={'rows2' + index} style={{ flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10 }}>
                  <Text style={ts4}>{item.label}</Text>
                  <Text style={ts4}>{item.pctg + ' %'}</Text>
                </View>
              );
            })}
          </View>
          <Text> </Text>
          <View style={{ flexDirection: 'row' }}>
            <View><Text style={discStyle}>*</Text></View>
            <View style={{ flex: 1 }}>
              <Text style={discStyle}>{disclaimer}</Text>
            </View>
          </View>

          <Text> </Text>
        </View>
      );
    }

    return (
      <View style={{ alignItems: 'center', justifyContent: 'center' }}>
        <View style={{ backgroundColor: 'white', width: sw, height: sh, paddingVertical: 20 }}>
          {/* <Text style={ts1}>TENTANG {overrideTitle(menuTitle.toUpperCase())}</Text>
          <Text style={subtitleStyle}>{menuSubtitle}</Text> */}
          <Text style={ts1}>TENTANG</Text>
          <Text> </Text>
          <Text style={descStyle}>{menuDesc}</Text>
          <Text> </Text>
          <Text> </Text>
          <View style={{ height: 1, backgroundColor: 'gainsboro' }} />
          <Text> </Text>

          <Text style={ts1}>TENTANG GIZI*</Text>
          {content}
          <View style={{ height: 1, backgroundColor: 'gainsboro' }} />
          <View style={{ height: sh }}>
            <Text> </Text>
            <Text style={ts1}>TENTANG MAKANAN</Text>
            <Text> </Text>
            <View style={{ flex: 1 }}>
              {this.renderThreeIcons()}
            </View>
          </View>
        </View>
      </View>
    );
  }

  // renderModalNF() {
  //   const menuTitle = this.state.menuSelected;

  //   const sw = this.state.sw * 0.8;
  //   const sh = this.state.sh * 0.8;
  //   const ts1 = { color: '#5BA545', fontWeight: 700, fontSize: PW(20) };
  //   const ts2 = { color: '#808080', fontWeight: 700, fontSize: PW(16) };
  //   const ts3 = { color: '#808080', fontWeight: 600, fontSize: PW(10), textAlign: 'center', zIndex: 2 };
  //   const ts4 = { color: '#808080', fontWeight: 600, fontSize: PW(12) };

  //   const nd = ND[menuTitle];

  //   let content = <View />;
  //   if (nd) {
  //     const total = nd.total;
  //     const rows = nd.top;
  //     const rows2 = nd.bottom;
  //     const uri = DONUTS[menuTitle];
  //     content = (
  //       <ScrollView style={{ paddingHorizontal: 20 }}>
  //         <View style={{ alignItems: 'center', justifyContent: 'center', paddingVertical: PH(26) }}>
  //           <ImageBackground source={{ uri: uri }} resizeMode='stretch' style={{ width: PW(96), height: PH(96), alignItems: 'center', justifyContent: 'center' }}>
  //           </ImageBackground>
  //           <Text> </Text>
  //           <Text style={ts2}>Energi Dikonsumsi</Text>
  //         </View>
  //         <View>
  //           {rows.map((item, index) => {
  //             let fullWidth = PW(165);
  //             let partWidth = item.pctg * fullWidth / 100;
  //             let hh = PH(16);
  //             let col = item.color;
  //             return (
  //               <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10 }}>
  //                 <Text style={ts4}>{item.label}</Text>
  //                 <View style={{ width: fullWidth, height: hh, backgroundColor: 'gainsboro' }}>
  //                   <View style={{ width: partWidth, height: hh, backgroundColor: col }}></View>
  //                   <View style={{ width: fullWidth, height: hh, position: 'absolute', alignItems: 'center', justifyContent: 'center' }}>
  //                     <Text style={ts3}>{item.text}</Text>
  //                   </View>
  //                 </View>
  //               </View>
  //             );
  //           })}
  //         </View>
  //         <Text> </Text>
  //         <View style={{ height: 1, backgroundColor: 'gainsboro' }} />
  //         <Text> </Text>
  //         <View>
  //           {rows2.map((item, index) => {
  //             return (
  //               <View key={'rows2' + index} style={{ flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10 }}>
  //                 <Text style={ts4}>{item.label}</Text>
  //                 <Text style={ts4}>{item.pctg + ' %'}</Text>
  //               </View>
  //             );
  //           })}
  //         </View>
  //       </ScrollView>
  //     );
  //   }

  //   return (
  //     <Modal
  //       isVisible={this.state.nfVisible}
  //       onBackdropPress={() => this.setState({ nfVisible: false })}
  //     >
  //       <View style={{ alignItems: 'center', justifyContent: 'center' }}>
  //         <View style={{ backgroundColor: 'white', width: sw, height: sh, borderRadius: 10, paddingVertical: 20 }}>
  //           <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 20 }}>
  //             <Text style={ts1}>INFORMASI NILAI GIZI</Text>
  //             <TouchableOpacity onPress={() => this.setState({ nfVisible: false })}>
  //               <Image source={{ uri: CLOSE }} style={{ width: PW(21), height: PH(21) }} />
  //             </TouchableOpacity>
  //           </View>
  //           {content}
  //         </View>
  //       </View>
  //     </Modal >
  //   );
  // }

  renderModalProduct() {
    const sw = this.state.sw * 0.8;
    const sh = this.state.sh * 0.8;
    const s1 = { color: '#5BA545', fontSize: 20 };
    const s2 = { color: '#5BA545' };
    const s3 = { color: '#808080' };
    const s4 = { color: '#808080', fontWeight: 'bold' };
    const s5 = { color: '#808080', textAlign: 'right' };
    const s6 = { color: '#5BA545', textDecorationLine: 'underline' };

    const data = this.state.selectedProduct;
    let name = '';
    let plantType = '';
    let plantDesc = 'Lorem ipsum et doros amet';
    let blockchainCode = '-';
    let batch = '';
    let podCode = '';
    let podAddress = '';
    let plantDate = '';
    let harvestDate = '';
    let botanist = '';
    let img = IMG['dummy.png'];
    let openExplorer = () => {

    }

    if (data) {
      const productType = data.productType;
      name = data.productName.split('-')[1].toUpperCase();
      plantType = 'microGREENS'; // data.productName.split('-')[0];
      plantDesc = PD[productType].desc;
      const pd = PD[productType];
      const fn = pd.img;
      img = IMG[fn] ? IMG[fn] : IMG['dummy.png'];

      const eurl = data.blockchainData ? data.blockchainData.explorerUrl : false;
      if (eurl) {
        const p0x = eurl.indexOf('0x');
        const code = eurl.substring(p0x);
        const code1 = code.substring(code, 5);
        const code2 = code.substring(code.length - 10);
        blockchainCode = code1 + '...' + code2;
        openExplorer = () => {
          Lib.openUrl(eurl);
        }
      }
      batch = data.codeBatch;
      podCode = data.pod.codeName;
      podAddress = data.pod.address;
      plantDate = moment().subtract(10, 'days').format('DD/MM/YYYY');
      harvestDate = data.harvestDate ? data.harvestDate : data.estHarvestDate;
      harvestDate = moment(harvestDate).format('DD/MM/YYYY');
      harvestDate = moment().format('DD/MM/YYYY');
      botanist = 'Imam';
    }

    return (
      <Modal
        isVisible={this.state.pVisible}
        onBackdropPress={() => this.setState({ pVisible: false })}
      >
        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
          <View style={{ backgroundColor: 'white', width: sw, height: sh, borderRadius: 10, overflow: 'hidden' }}>
            <ImageBackground source={{ uri: img }} style={{ height: 100, overflow: 'hidden' }} >
              <View style={{ alignItems: 'flex-end', padding: 20 }}>
                <TouchableOpacity onPress={() => this.setState({ pVisible: false })}>
                  <Image source={{ uri: CLOSEWHITE }} style={{ width: PW(21), height: PH(21) }} />
                </TouchableOpacity>
              </View>
            </ImageBackground>
            <ScrollView style={{ flex: 1, padding: 20 }}>
              <Text style={s1}>{name}</Text>
              <Text style={s2}>{plantType}</Text>
              <Text> </Text>
              <Text style={s3}>{plantDesc}</Text>
              <Text> </Text>
              <View style={{ height: 1, backgroundColor: 'gainsboro' }} />
              <Text> </Text>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={s4}>Kode Blockchain</Text>
                <TouchableOpacity onPress={() => openExplorer()}>
                  <Text style={s6}>{blockchainCode}</Text>
                </TouchableOpacity>
              </View>
              <Text> </Text>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={s4}>Kode Batch</Text>
                <Text style={s5}>{batch}</Text>
              </View>
              <Text> </Text>
              <View style={{ height: 1, backgroundColor: 'gainsboro' }} />
              <Text> </Text>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={s4}>Kode Pod</Text>
                <Text style={s5}>{podCode}</Text>
              </View>
              <Text> </Text>
              <Text style={s4}>Lokasi Pod</Text>
              <Text style={s3}>{podAddress}</Text>
              <Text> </Text>
              <View style={{ height: 1, backgroundColor: 'gainsboro' }} />
              <Text> </Text>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={s4}>Tanggal Tanam</Text>
                <Text style={s5}>{plantDate}</Text>
              </View>
              <Text> </Text>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={s4}>Tanggal Panen</Text>
                <Text style={s5}>{harvestDate}</Text>
              </View>
              <Text> </Text>
              <View style={{ height: 1, backgroundColor: 'gainsboro' }} />
              <Text> </Text>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={s4}>Botanist</Text>
                <Text style={s2}>{botanist}</Text>
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    );
  }

  renderCell(item) {
    const productName = item.productName;
    const productType = item.productType;
    const pd = PD[productType];
    const fn = pd.img;
    const img = IMG[fn] ? IMG[fn] : IMG['dummy.png'];
    const arr = productName.split('-');
    const type = 'microGREENS'; //arr[0];
    const name = arr[1];
    const uri = img;
    return (
      <View style={{ flex: 1, paddingVertical: 10 }}>
        <ImageBackground source={{ uri }} style={{ height: 140, borderRadius: 15, justifyContent: 'flex-end', overflow: "hidden" }}>
          <TouchableHighlight onPress={() => this.toggleProduct(item)}>
            <View style={{ height: 60, backgroundColor: '#5BA545', paddingHorizontal: 10, justifyContent: 'center' }}>
              <Text style={{ color: 'white', fontSize: 10 }}>{type}</Text>
              <Text style={{ color: 'white', fontSize: 14, fontWeight: 'bold' }}>{name.toUpperCase()}</Text>
            </View>
          </TouchableHighlight>
        </ImageBackground>
      </View>
    );
  }

  // renderContent() {
  //   const pW = this.state.mulW;
  //   const pH = this.state.mulH;
  //   const data = this.state.data;

  //   const fontColor = '#5BA545';
  //   const menuTitle = data.title;
  //   const menuSubtitle = 'Resep oleh ' + data.creatorName;
  //   const menuDesc = data.description;
  //   const panelHeightShort = 60;
  //   const products = data.products;

  //   // for (let i = 0; i < 8; i++) {
  //   //   if (i % 2 === 0)
  //   //     products.push({ _id: i, type: 'MicroGREENS', name: 'Brocolli' });
  //   //   else
  //   //     products.push({ _id: i, type: 'BabyGREENS', name: 'Red Sorrel' });
  //   // }

  //   const products2d = [];
  //   let p2dRow = [];
  //   for (let i = 0; i < products.length; i++) {
  //     p2dRow.push(products[i]);
  //     if (p2dRow.length === 2) {
  //       products2d.push(p2dRow);
  //       p2dRow = [];
  //     }
  //   }

  //   if (p2dRow.length > 0) products2d.push(p2dRow);

  //   let panelHeight = panelHeightShort;
  //   let panelContent = <View />;
  //   let panelProduct = <View />;

  //   if (this.state.showPanel) {
  //     if (this.state.panelProductHeight) {
  //       panelProduct = (
  //         <ScrollView style={{ height: this.state.panelProductHeight }}>
  //           <Text> </Text>
  //           <Text style={{ color: fontColor, fontWeight: 700, fontSize: 12 }}>Cari Tahu Blockchain Sumber Sayuranmu Dengan Klik Foto Berikut</Text>
  //           <Text></Text>
  //           {
  //             products2d.map((item, index) => {
  //               const cell1 = this.renderCell(item[0]);
  //               let cell2 = <View style={{ flex: 1 }} />;
  //               if (item.length > 1) cell2 = this.renderCell(item[1]);
  //               return (
  //                 <View key={'row' + index} style={{ flexDirection: 'row' }}>
  //                   {cell1}
  //                   <View style={{ width: 20 }} />
  //                   {cell2}
  //                 </View>
  //               );
  //             })
  //           }
  //         </ScrollView >
  //       );
  //     }
  //     panelHeight = (this.state.sh * 0.7);
  //     panelContent = (
  //       <View style={{ paddingHorizontal: 20, height: (panelHeight - panelHeightShort) }}>
  //         <Text style={{ color: fontColor, fontSize: 20, fontWeight: 700 }}>{menuTitle}</Text>
  //         <Text style={{ color: fontColor }}> {menuSubtitle}</Text>
  //         <Text> </Text>
  //         <Text style={{ color: '#808080' }}>{menuDesc}</Text>
  //         <Text> </Text>
  //         <TouchableOpacity onPress={() => this.toggleNutritionFact(menuTitle)}>
  //           <Text style={{ color: fontColor }}>Check Nutrition Fact >></Text>
  //         </TouchableOpacity>
  //         <Text> </Text>
  //         <View style={{ height: 1, backgroundColor: 'gainsboro' }} />
  //         <View style={{ flex: 1 }} onLayout={e => {
  //           const panelProductHeight = e.nativeEvent.layout.height;
  //           if (!this.state.panelProductHeight) this.setState({ panelProductHeight });
  //         }}>
  //           {panelProduct}
  //         </View>
  //       </View >
  //     );
  //   }

  //   let panel = (
  //     <View style={{ backgroundColor: 'white', height: panelHeight, borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
  //       <View style={{ height: panelHeightShort }}>
  //         <TouchableOpacity onPress={() => this.setState({ showPanel: !(this.state.showPanel) })}>
  //           <View style={{ alignItems: 'center', padding: 5 }}>
  //             <View style={{ width: 26, height: 2, backgroundColor: '#5BA54580' }}></View>
  //             <View style={{ width: 26, height: 2, backgroundColor: '#5BA54580', top: 5 }}></View>
  //           </View>
  //         </TouchableOpacity>
  //       </View>
  //       {panelContent}
  //     </View>
  //   );

  //   let down;
  //   if (panelHeight <= 70) {
  //     down = (
  //       <View style={{ padding: 20, alignItems: 'center', justifyContent: 'center' }}>
  //         <TouchableOpacity onPress={() => this.showThreeIcons()}>
  //           <Image source={{ uri: DOWN }} style={{ width: PW(21), height: PH(21) }} />
  //         </TouchableOpacity>
  //       </View>
  //     );
  //   }

  //   const bg = BG[menuTitle];

  //   return (
  //     <ImageBackground source={{
  //       uri: bg
  //     }} resizeMode="cover" style={{ flex: 1 }}>
  //       {down}
  //       <View style={{ flex: 1, justifyContent: 'flex-end' }}>
  //         {panel}
  //       </View>
  //     </ImageBackground>
  //   );
  // }

  async showThreeIcons() {
    this.setState({ animOpen: true });
    await Lib.delay(1000);
    this.setState({ show: 'threeIcons' });
  }

  // showInfo() {
  //   this.setState({ show: 'info' });
  // }

  showBack() {
    this.setState({ show: 'back' });
  }

  showFront() {
    this.setState({ show: 'front' });
  }

  renderCells() {
    const data = this.state.data;
    const products = data.products;

    const products2d = [];
    let p2dRow = [];
    for (let i = 0; i < products.length; i++) {
      p2dRow.push(products[i]);
      if (p2dRow.length === 2) {
        products2d.push(p2dRow);
        p2dRow = [];
      }
    }

    if (p2dRow.length > 0) products2d.push(p2dRow);

    return (
      <View style={{ paddingVertical: 10 }}>
        {
          products2d.map((item, index) => {
            const cell1 = this.renderCell(item[0]);
            let cell2 = <View style={{ flex: 1 }} />;
            if (item.length > 1) cell2 = this.renderCell(item[1]);
            return (
              <View key={'row' + index} style={{ flexDirection: 'row' }}>
                {cell1}
                <View style={{ width: 20 }} />
                {cell2}
              </View>
            );
          })
        }
      </View>
    );
  }

  renderFront() {
    const ts = { textAlign: 'center', color: '#5BA545', fontSize: PW(14), fontWeight: 700 };
    const data = this.state.data;

    const fontColor = '#5BA545';
    const col2 = '#036A3A';
    const menuTitle = data.title;
    const subtitle = 'Cek BLOCKCHAIN Sumber Makananmu Disini!';

    let content = null;

    if (this.state.animDone) {
      const ts = { fontSize: PH(10), textAlign: 'center', color: 'white' };
      const ts2 = { fontWeight: 800, color: '#FFC93F' };
      content = (
        <Animatable.View duration={300} animation="fadeIn" style={{ flex: 1 }}>
          <View style={{ paddingHorizontal: PW(20) }}>
            <View style={{ backgroundColor: '#5BA545', padding: 7, borderRadius: 16 }}>
              <View style={{ flex: 1, padding: 10, borderColor: 'white', borderWidth: 1, borderRadius: 16, alignItems: 'center', justifyContent: 'center', borderStyle: 'dashed' }}>
                <Text style={ts}><Text style={ts2}>Tahukah Kamu?</Text> Semua benih greens tidak menggunakan
                  treatment kimia loh sehingga aman di makan.</Text>
              </View>
            </View>
          </View>
          <View style={{ padding: PH(20), paddingHorizontal: 30 }}>
            <Text style={{ color: fontColor, fontSize: PH(16), fontWeight: 700, textAlign: 'center' }}>{overrideTitle(menuTitle)}</Text>
            <Text style={{ color: col2, fontSize: PH(12), fontWeight: 700, textAlign: 'center' }}>{subtitle}</Text>
          </View>
          <View style={{ flex: 1, paddingHorizontal: PW(20) }}>
            <View style={{ flex: 1, backgroundColor: '#E2E2E2', borderRadius: 16, overflow: 'hidden', paddingHorizontal: 16 }}>
              <ScrollView style={{ flex: 1, backgroundColor: '#E2E2E2' }} showsVerticalScrollIndicator={false} >
                {this.renderCells()}
              </ScrollView>
            </View>
          </View>
          <View style={{ paddingVertical: PH(40), alignItems: 'center' }}>
            <TouchableHighlight style={{ backgroundColor: fontColor, borderRadius: 16, overflow: 'hidden', paddingVertical: PH(8), paddingHorizontal: PW(37) }} onPress={() => this.showBack()}>
              <Text style={{ color: 'white', fontWeight: 700 }}>Informasi Makanan dan Gizi</Text>
            </TouchableHighlight>
          </View>
        </Animatable.View>
      );

    } else {
      const disclaimerTxt = 'Saat ini, GREENS berada dalam periode Beta Testing dan untuk itu saya paham dan setuju bahwa menu, informasi serta konten yang berada dalam website dan aplikasi GREENS masih dapat berubah. GREENS terus berupaya memberikan nutrisi terbaik dari setiap menu yang dipersiapkan oleh GREENS yang telah dikaji oleh Ahli Gizi yang tersertifikasi untuk memenuhi Angka Kecukupan Gizi (AKG) harian. Dalam masa Beta Testing ini, saya memaklumi ketidaksempurnaan dan perubahan yang dapat terjadi dan tidak akan menuntut pihak GREENS terkait hal ini. Saya juga bersedia untuk memberikan kritik dan saran untuk membantu proses pengembangan dalam masa Beta Testing berlangsung.';
      content = (
        <View style={{ flex: 1 }}>
          <View style={{ flex: 1, paddingBottom: 250 }}>
            <ImageBackground source={{ uri: SHADOW }} resizeMode='cover' style={{ flex: 1 }}>
              <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Animatable.View animation='bounce' duration={1000} iterationCount='infinite'>
                  <Image source={{ uri: BENTOBOX }} style={{ height: PH(280), width: PW(280), resizeMode: 'contain' }} />
                </Animatable.View>
              </View>
            </ImageBackground>
          </View>
          <View style={{
            height: '100%',
            width: '100%',
            paddingHorizontal: PW(20),
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
            <View style={{ paddingVertical: 16, paddingHorizontal: PW(20), borderWidth: 1, borderRadius: 16, borderColor: fontColor }}>
              <Text style={{ textAlign: 'center', fontSize: 14, color: fontColor, fontWeight: 800 }}>PERHATIAN!</Text>
              <Text> </Text>
              <Text style={{ textAlign: 'center', fontSize: 10, color: fontColor, fontWeight: 800 }}>
                {disclaimerTxt}
              </Text>
            </View>
            <View style={{ paddingVertical: PH(40), alignItems: 'center' }}>
              <TouchableHighlight style={{ backgroundColor: fontColor, borderRadius: 16, overflow: 'hidden', paddingVertical: PH(8), paddingHorizontal: PW(37) }} onPress={() => this.setState({ animDone: true })}>
                <Text style={{ color: 'white', fontWeight: 700 }}>Lanjutkan Bila Setuju</Text>
              </TouchableHighlight>
            </View>
          </View>

        </View>
      );
    }

    return (
      <View style={{ flex: 1, backgroundColor: 'white' }}>
        <View style={{ padding: PH(14) }}>
          <Image source={{ uri: LOGO }} style={{ height: PH(44), resizeMode: 'contain' }} />
        </View>
        {content}
      </View>
    );
  }

  outBackView() {
    this.backViewRef.fadeOut(300).then(endState => { if (endState.finished) this.setState({ show: 'front' }) });
  }

  renderBack() {
    const ts = { textAlign: 'center', color: '#5BA545', fontSize: PW(14), fontWeight: 700 };
    const data = this.state.data;

    const fontColor = '#5BA545';
    const col2 = '#036A3A';
    const menuTitle = 'INFORMASI MAKANAN DAN GIZI';

    const sw = this.state.sw;
    const sh = this.state.sh;

    return (
      <Animatable.View ref={ref => { this.backViewRef = ref; }} duration={300} animation="fadeInRightBig" style={{ position: 'absolute', width: sw, height: sh, backgroundColor: 'white' }}>
        <View style={{ height: PH(50), borderBottomColor: '#eeeeee', borderBottomWidth: 1, flexDirection: 'row' }}>
          <View style={{ width: 30, alignItems: 'center', justifyContent: 'center' }}>
            <TouchableHighlight onPress={() => this.outBackView()}>
              <Image source={{ uri: BACK }} style={{ height: PH(44), width: 10, resizeMode: 'contain' }} />
            </TouchableHighlight>
          </View>
          <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <Text style={{ color: fontColor, fontSize: PH(16), fontWeight: 700, textAlign: 'center' }}>{menuTitle}</Text>
          </View>
          <View style={{ width: 30 }}></View>
          {/* <Text style={{ color: fontColor, fontSize: PH(16), fontWeight: 700, textAlign: 'center' }}>{menuTitle}</Text> */}
        </View>
        <View style={{ flex: 1 }}>
          <ScrollView style={{ flex: 1 }}>
            {this.renderNF()}
          </ScrollView>
        </View>
      </Animatable.View>
    );
  }


  // renderFrontOld() {
  //   const ts = { textAlign: 'center', color: '#5BA545', fontSize: PW(14), fontWeight: 700 };

  //   if (this.state.animOpen) {
  //     const sw = this.state.sw;
  //     const sh = this.state.sh;
  //     return (
  //       <View style={{ flex: 1, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }}>
  //         <View style={{ paddingTop: PH(55) }}>
  //           <Image source={{ uri: LOGO }} style={{ height: PH(64), resizeMode: 'contain' }} />
  //         </View>
  //         <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
  //           <ImageBackground source={{ uri: STAR }} resizeMode='contain' style={{ height: PH(360), width: PW(360) }}>
  //           </ImageBackground>
  //         </View>
  //         <View style={{ paddingBottom: PH(60) }}>
  //         </View>
  //         <Image source={{ uri: BENTOOPEN }} style={{ height: sh, width: sw, resizeMode: 'cover', position: 'absolute' }} />
  //       </View>
  //     );
  //   }


  //   return (
  //     <View style={{ flex: 1, backgroundColor: 'white' }}>
  //       <View style={{ paddingTop: PH(55) }}>
  //         <Image source={{ uri: LOGO }} style={{ height: PH(64), resizeMode: 'contain' }} />
  //       </View>
  //       <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
  //         <ImageBackground source={{ uri: STAR }} resizeMode='contain' style={{ height: PH(360), width: PW(360) }}>
  //           <TouchableHighlight onPress={() => this.showThreeIcons()}>
  //             <Image source={{ uri: BENTOBOX }} style={{ height: PH(360), width: PW(360), resizeMode: 'contain' }} />
  //           </TouchableHighlight>
  //         </ImageBackground>
  //       </View>
  //       <View style={{ paddingBottom: PH(60) }}>
  //         <Text style={ts}>Tap Kotak Makanan</Text>
  //         <Text style={ts}>untuk Mengungkap Misterinya</Text>
  //       </View>
  //     </View>
  //   );
  // }

  renderThreeIcons() {
    const ts = { color: '#C77700', fontWeight: 'bold', fontSize: PW(14) }
    const ts1 = { color: '#5BA545', fontWeight: 700, fontSize: PW(12), textAlign: 'center' };
    const ts2 = { color: '#808080', fontWeight: 500, fontSize: PW(10), textAlign: 'center' };
    const anim = 'pulse';
    const vs = { alignItems: 'center', justifyContent: 'center' };
    return (
      <View style={{ flex: 1, alignItems: 'center', paddingVertical: 40 }}>
        <View style={{ width: PW(228) }}>
          <View style={vs}>
            <Animatable.View animation={anim} iterationCount='infinite'>
              <Image source={{ uri: GREENER1 }} style={{ width: PW(70), height: PH(70) }} />
            </Animatable.View>
            <Text style={ts1}>DARI MANA ASAL MAKANANMU</Text>
            <Text style={ts2}>Sayuranmu berasal dari Live Vegetables yang dipanen langsung ditempat yang sama sebelum dimasak.</Text>
            <Text> </Text>
          </View>
          <View style={vs}>
            <Animatable.View animation={anim} iterationCount='infinite'>
              <Image source={{ uri: GREENER2 }} style={{ width: PW(70), height: PH(70) }} />
            </Animatable.View>
            <Text style={ts1}>KEKUATAN DALAM MAKANANMU</Text>
            <Text style={ts2}>Sayuranmu memiliki kekuatan langka, ajaib, dan sangat dibutuhkan tubuhmu yang bernama Micro Nutrients.</Text>
            <Text> </Text>
          </View>
          <View style={vs}>
            <Animatable.View animation={anim} iterationCount='infinite'>
              <Image source={{ uri: GREENER3 }} style={{ width: PW(70), height: PH(70) }} />
            </Animatable.View>
            <Text style={ts1}>KEAMANAN MAKANANMU</Text>
            <Text style={ts2}>Sayuranmu diperhatikan sejak masih bibit hingga menjadi hidangan untukmu yang direkam dalam sistem canggih bernama Blockchain.</Text>
            <Text> </Text>
          </View>
        </View>
      </View>
    );
  }

  // renderThreeIconsOld() {
  //   const ts = { color: '#C77700', fontWeight: 'bold', fontSize: PW(14) }
  //   const ts1 = { color: '#5BA545', fontWeight: 700, fontSize: PW(12), textAlign: 'center' };
  //   const ts2 = { color: '#808080', fontWeight: 500, fontSize: PW(10), textAlign: 'center' };
  //   const vs = { alignItems: 'center', justifyContent: 'center' };
  //   return (
  //     <View style={{ flex: 1, backgroundColor: 'white' }}>
  //       <View style={{ paddingTop: PH(10) }}>
  //         <Image source={{ uri: LOGO }} style={{ height: PH(64), overflow: 'hidden', resizeMode: 'contain' }} />
  //       </View>
  //       <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
  //         <View style={{ width: PW(228) }}>
  //           <View style={vs}>
  //             <Image source={{ uri: GREENER1 }} style={{ width: PW(70), height: PH(70) }} />
  //             <Text style={ts1}>DARI MANA ASAL MAKANANMU</Text>
  //             <Text style={ts2}>Sayuranmu berasal dari Live Vegetables yang dipanen langsung ditempat yang sama sebelum dimasak.</Text>
  //             <Text> </Text>
  //           </View>
  //           <View style={vs}>
  //             <Image source={{ uri: GREENER2 }} style={{ width: PW(70), height: PH(70) }} />
  //             <Text style={ts1}>KEKUATAN DALAM MAKANANMU</Text>
  //             <Text style={ts2}>Sayuranmu memiliki kekuatan langka, ajaib, dan sangat dibutuhkan tubuhmu yang bernama Micro Nutrients.</Text>
  //             <Text> </Text>
  //           </View>
  //           <View style={vs}>
  //             <Image source={{ uri: GREENER3 }} style={{ width: PW(70), height: PH(70) }} />
  //             <Text style={ts1}>KEAMANAN MAKANANMU</Text>
  //             <Text style={ts2}>Sayuranmu diperhatikan sejak masih bibit hingga menjadi hidangan untukmu yang direkam dalam sistem canggih bernama Blockchain.</Text>
  //             <Text> </Text>
  //           </View>
  //         </View>
  //       </View>
  //       <View style={{ paddingBottom: PH(50), alignItems: 'center' }}>
  //         <TouchableHighlight style={{ backgroundColor: '#FFC93F', borderRadius: 16, overflow: 'hidden', paddingVertical: PH(8), paddingHorizontal: PW(37) }} onPress={() => this.showInfo()}>
  //           <Text style={ts}>Isi Makananmu</Text>
  //         </TouchableHighlight>
  //       </View>
  //     </View>
  //   );
  // }

  renderWarning() {
    const ww = this.state.ww;
    const wh = this.state.wh;

    const ts = { textAlign: 'center', color: '#5BA545', fontSize: PW(14), fontWeight: 700 };
    return (
      <View style={{ width: ww, height: wh, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }}>
        <Text style={ts}>SILAHKAN SCAN QR CODE</Text>
      </View>
    );
  }

  renderError() {
    const ww = this.state.ww;
    const wh = this.state.wh;

    const ts = { textAlign: 'center', color: '#5BA545', fontSize: PW(14), fontWeight: 700 };
    return (
      <View style={{ width: ww, height: wh, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }}>
        <Text style={ts}>Maaf atas ketidaknyamanannya system sedang terganggu</Text>
        <Text style={ts}>Untuk info lebih lanjut bisa wa ke</Text>
        <TouchableHighlight onPress={() => Lib.openUrl('https://wa.me/+6281211009589')}><Text style={ts}>wa.me/+6281211009589</Text></TouchableHighlight>
      </View>
    );
  }


  render() {
    if (this.state.show === 'warning') return this.renderWarning();
    if (this.state.show === 'error') return this.renderError();
    if (!this.state.wh) return null;

    const ww = this.state.ww;
    const wh = this.state.wh;
    const sw = this.state.sw;
    const sh = this.state.sh;

    let content = null;

    // if (this.state.show === 'back') {
    //   content = (
    //     <View style={{ width: sw, height: sh, backgroundColor: 'white' }}>
    //       {this.renderBack()}
    //     </View>
    //   );
    // } else if (this.state.show === 'front') {
    let backView = null;
    if (this.state.show === 'back') backView = this.renderBack();
    content = (
      <View style={{ width: sw, height: sh, backgroundColor: 'white' }}>
        {this.renderModalProduct()}
        {this.renderFront()}
        {backView}
      </View>
    );
    // }

    if (this.state.landscape) {
      return (
        <View style={{ width: ww, height: wh, backgroundColor: 'gainsboro', alignItems: 'center', justifyContent: 'center' }}>
          {content}
        </View>
      );
    }

    return content;

  }

}

export default App;
