import io from 'socket.io-client';
import feathers from '@feathersjs/client';

const URL = 'https://spinach.greens.id';
// const URL = 'https://8044-13-212-247-243.ngrok.io';
// const URL = 'http://localhost:3030';
console.log('Connected to:' + URL);
const socket = io(URL);
const app = feathers();

app.configure(feathers.socketio(socket, {
  timeout: 180000
}));

app.configure(feathers.authentication());

class Singleton {
  constructor() {
    this.pods = [];
    this.user = {};
  }

  getUrl() {
    return URL;
  }

  async loginByAccessToken(accessToken) {
    const result = await app.authenticate({
      strategy: 'jwt',
      accessToken: accessToken
    });
    this.user = result.user;
  }

  async loginByEmailPassword(email, password) {
    const result = await app.authenticate({
      strategy: 'local',
      email,
      password
    });
    this.user = result.user;
  }

  async getData(code) {
    const siService = app.service('sale-item');
    const data = await siService.get(code);
    return data;
  }
}

export default new Singleton();

