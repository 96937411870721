const data = {};
data['07'] = { name: 'Red Veined Sorrel', desc: 'microGREENS Red Veined Sorrel memiliki keunikan bentuk dan rasa serta beragam manfaat dikarenakan kandungan Vitamin C yang tinggi serta beberapa jenis Vitamin B. Selain itu, microGREENS Red Veined Sorrel juga kaya akan antioksidan, kalsium, potasium, magnesium, dan fosforus.', img: 'redveinedsorrel.png' }
data['17'] = { name: 'Parsley', desc: 'microGREENS Parsley termasuk ke dalam salah satu jenis tanaman Superfood karena kandungan Vitamin C, Vitamin K, dan Vitamin A yang tinggi. Selain itu microGREENS Parsley juga kaya akan asam folat, zat besi, kalsium dan magnesium.', img: 'parsley.png' }
data['08'] = { name: 'Basil', desc: 'microGREENS Basil termasuk sebagai salah satu jenis herba (herbs) yang tinggi Protein, kaya akan Vitamin E, A, K, C, B6, dan beragam jenis mineral seperti kalsium, zat besi, zinc dan potasium.', img: 'basil.png' }
data['02'] = { name: 'Green Radish', desc: 'Kaya akan Vitamin C, bertekstur renyah dan ada sedikit rasa pedas.', img: 'greenradish.png' }
data['12'] = { name: 'Bok Choy', desc: 'Tinggi kadar Antioksidan dan beragam Vitamin seperti Vitamin C, Vitamin E dan Vitamin K. microGREENS BokChoy memiliki rasa yang cenderung manis, tidak pedas, memiliki kadar air yang cukup banyak.', img: 'bokchoy.png' }
data['18'] = { name: 'Coriander', desc: 'Kaya akan Vitamin A, B, C, E, K, Beta-Karoten, Kalsium, Zat Besi, Potasium hingga Zinc.', img: 'coriander.png' }
data['10'] = { name: 'Kale', desc: 'Memiliki kandungan Antioksidan yang tinggi serta kaya akan Vitamin C & Vitamin K. Rasa microGREENS Kale cenderung memiliki rasa manis dan terasa seperti brokoli.', img: 'kale.png' }
data['01'] = { name: 'Red Radish', desc: 'Kaya akan Vitamin C, bertekstur renyah dan ada sedikit rasa pedas. microGREENS Red Radish memiliki warna yang unik di bagian batangnya seperti warna merah muda.', img: 'redradish.png' }
data['04'] = { name: 'Green Amaranth', desc: '', img: 'greenamaranth.png' }
data['11'] = { name: 'Brocolli', desc: '', img: 'brocolli.png' }
data['05'] = { name: 'Red Cabbage', desc: 'Red cabbage microGREENS mengandung 261x beta karoten, 40x Vitamin E, dan 70x Vitamin K dibanding sayuran biasa. Selain itu, red cabbage microGREENS juga mengandung zat besi, vitamin C, dan potasium yang tinggi', img: 'redcabbage.png' }
data['19'] = { name: 'Wheatgrass', desc: 'Wheatgrass mengandung Selenium yang tinggi, vitamin A, C, E, K dan B kompleks, serta 17 asam amino. Manfaat wheatgrass yaitu mengurangi racun dalam tubuh, melancarkan pencernaan, mengontrol gula darah, memperkuat imun tubuh, dan melawan penyakit kanker', img: 'wheatgrass.png' }
data['06'] = { name: 'Pea Shoot', desc: '', img: 'peashoot.png' }
data['03'] = { name: 'Red Amaranth', desc: '', img: 'redamaranth.png' }

const COL1 = '#FFEA9F';
const COL2 = '#B2F89D';
const COL3 = '#57D1F8';
const COL4 = '#FF6363';

const nutriData = {};
nutriData['SORREL ROASTED SALMON'] = {
  total: 481,
  top: [
    { label: 'Energi', pctg: 35, text: '481.2 kcal / 1356 kcal (35%)', color: COL1 }, // 
    { label: 'Protein', pctg: 34, text: '29.2 g / 84.8 g (34%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 14, text: '21.0 g / 152.6 g (14%)', color: COL3 },
    { label: 'Lemak', pctg: 66, text: '30.0 g / 45.2 g (66%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 4 },
    { label: 'Zat Besi', pctg: 26 },
    { label: 'Kalsium', pctg: 2 },
    { label: 'Vit. A', pctg: 8 },
    { label: 'Vit. C', pctg: 33 },
    { label: 'Vit. B12', pctg: 443 },
    { label: 'Asam Folat', pctg: 10 },
    { label: 'Potasium', pctg: 43 }
  ]
}

nutriData['CORIANDER CHICKEN RICE'] = {
  total: 442,
  top: [
    { label: 'Energi', pctg: 32, text: '442.0 kcal / 1380 kcal (32%)', color: COL1 },
    { label: 'Protein', pctg: 34, text: '29.0 g / 86.3 g (34%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 21, text: '32.4 g / 155.3 g (21%)', color: COL3 },
    { label: 'Lemak', pctg: 46, text: '21.1 g / 46.0 g (66%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 4 },
    { label: 'Zat Besi', pctg: 26 },
    { label: 'Kalsium', pctg: 2 },
    { label: 'Vit. A', pctg: 8 },
    { label: 'Vit. C', pctg: 2 },
    { label: 'Vit. B12', pctg: 17 },
    { label: 'Asam Folat', pctg: 6 },
    { label: 'Potasium', pctg: 7 }
  ]
}

nutriData['CHICKEN MARINARA'] = {
  total: 465,
  top: [
    { label: 'Energi', pctg: 34, text: '464.5 kcal / 1380 kcal (34%)', color: COL1 },
    { label: 'Protein', pctg: 41, text: '35.1 g / 86.3 g (41%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 23, text: '35.2 g / 155.3 g (23%)', color: COL3 },
    { label: 'Lemak', pctg: 40, text: '18.3 g / 46.0 g (40%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 9 },
    { label: 'Zat Besi', pctg: 54 },
    { label: 'Kalsium', pctg: 13 },
    { label: 'Vit. A', pctg: 45 },
    { label: 'Vit. C', pctg: 13 },
    { label: 'Vit. B12', pctg: 23 },
    { label: 'Asam Folat', pctg: 50 },
    { label: 'Potasium', pctg: 24 }
  ]
}

nutriData['LOCO MOCO'] = {
  total: 465,
  top: [
    { label: 'Energi', pctg: 36, text: '490.0 kcal / 1356 kcal (36%)', color: COL1 },
    { label: 'Protein', pctg: 42, text: '35.3 g / 84.8 g (42%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 25, text: '37.6 g / 152.6 g (25%)', color: COL3 },
    { label: 'Lemak', pctg: 44, text: '19.7 g / 45.2 g (44%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 12 },
    { label: 'Zat Besi', pctg: 85 },
    { label: 'Kalsium', pctg: 6 },
    { label: 'Vit. A', pctg: 19 },
    { label: 'Vit. C', pctg: 8 },
    { label: 'Vit. B12', pctg: 147 },
    { label: 'Asam Folat', pctg: 19 },
    { label: 'Potasium', pctg: 21 }
  ]
}

nutriData['GREENS NUGGETS'] = {
  total: 474,
  description: 'Nuggets full protein yang dilengkapi dengan kentang panggang dan kacang polong cream. Kaya akan Zat Besi dan Vitamin A.',
  top: [
    { label: 'Energi', pctg: 34, text: '473.6 kcal / 1380 kcal (34%)', color: COL1 },
    { label: 'Protein', pctg: 21, text: '17.8 g / 86.3 g (21%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 35, text: '54.2 g / 155.3 g (35%)', color: COL3 },
    { label: 'Lemak', pctg: 43, text: '20.0 g / 46.0 g (43%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 17 },
    { label: 'Zat Besi', pctg: 51 },
    { label: 'Kalsium', pctg: 7 },
    { label: 'Vit. A', pctg: 46 },
    { label: 'Vit. C', pctg: 32 },
    { label: 'Vit. B12', pctg: 7 },
    { label: 'Asam Folat', pctg: 11 },
    { label: 'Potasium', pctg: 36 }
  ]
}

nutriData['SUPREME SALMON TERIYAKI'] = {
  total: 453,
  top: [
    { label: 'Energi', pctg: 33, text: '453.1 kcal / 1356 kcal (33%)', color: COL1 },
    { label: 'Protein', pctg: 42, text: '35.4 g / 84.8 g (42%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 24, text: '36.9 g / 152.6 g (24%)', color: COL3 },
    { label: 'Lemak', pctg: 36, text: '16.4 g / 45.2 g (36%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 7 },
    { label: 'Zat Besi', pctg: 51 },
    { label: 'Kalsium', pctg: 5 },
    { label: 'Vit. A', pctg: 21 },
    { label: 'Vit. C', pctg: 4 },
    { label: 'Vit. B12', pctg: 40 },
    { label: 'Asam Folat', pctg: 18 },
    { label: 'Potasium', pctg: 15 }
  ]
}

nutriData['GREENS RICE WITH CHICKEN KATSU'] = {
  total: 582,
  top: [
    { label: 'Energi', pctg: 42, text: '581.9 kcal / 1380 kcal (42%)', color: COL1 },
    { label: 'Protein', pctg: 54, text: '46.7 g / 86.3 g (54%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 34, text: '52.7 g / 155.3 g (34%)', color: COL3 },
    { label: 'Lemak', pctg: 36, text: '16.4 g / 46.0 g (36%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 12 },
    { label: 'Zat Besi', pctg: 74 },
    { label: 'Kalsium', pctg: 9 },
    { label: 'Vit. A', pctg: 25 },
    { label: 'Vit. C', pctg: 4 },
    { label: 'Vit. B12', pctg: 66 },
    { label: 'Asam Folat', pctg: 20 },
    { label: 'Potasium', pctg: 20 }
  ]
}

nutriData['GREENS RICE WITH CHICKEN KATSU'] = {
  description: 'Ayam goreng Jepang yang dilengkapi dengan GREENS Rice yang penuh dengan microGREENS serta sayuran lainnya dan scrambled egg. Kaya akan Zat Besi dan Vitamin B12.',
  total: 582,
  top: [
    { label: 'Energi', pctg: 42, text: '581.9 kcal / 1380 kcal (42%)', color: COL1 },
    { label: 'Protein', pctg: 54, text: '46.7 g / 86.3 g (54%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 34, text: '52.7 g / 155.3 g (34%)', color: COL3 },
    { label: 'Lemak', pctg: 36, text: '16.4 g / 46.0 g (36%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 12 },
    { label: 'Zat Besi', pctg: 74 },
    { label: 'Kalsium', pctg: 9 },
    { label: 'Vit. A', pctg: 25 },
    { label: 'Vit. C', pctg: 4 },
    { label: 'Vit. B12', pctg: 66 },
    { label: 'Asam Folat', pctg: 20 },
    { label: 'Potasium', pctg: 20 }
  ]
}

nutriData['LUCKY LOCO MOCO'] = {
  description: 'Makanan a la Hawaii yang di load dengan microGREENS dan housemade gravy. Kaya akan Zat Besi dan Vitamin B12.',
  total: 490,
  top: [
    { label: 'Energi', pctg: 36, text: '490.0 kcal / 1356 kcal (36%)', color: COL1 },
    { label: 'Protein', pctg: 42, text: '35.3 g / 84.8 g (42%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 25, text: '37.6 g / 152.6 g (25%)', color: COL3 },
    { label: 'Lemak', pctg: 44, text: '19.7 g / 45.2 g (44%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 12 },
    { label: 'Zat Besi', pctg: 85 },
    { label: 'Kalsium', pctg: 6 },
    { label: 'Vit. A', pctg: 19 },
    { label: 'Vit. C', pctg: 8 },
    { label: 'Vit. B12', pctg: 147 },
    { label: 'Asam Folat', pctg: 19 },
    { label: 'Potasium', pctg: 21 }
  ]
}

nutriData['MIGHTY CORIANDER CHICKEN RICE'] = {
  description: 'Nasi daun ketumbar yang sangat bernutrisi dengan Ayam Charsiu a la GREENS dan telur kecap. Kaya akan Protein dan Lemak tak jenuh.',
  total: 442,
  top: [
    { label: 'Energi', pctg: 32, text: '442.0 kcal / 1380 kcal (32%)', color: COL1 },
    { label: 'Protein', pctg: 34, text: '29.0 g / 86.3 g (34%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 21, text: '32.4 g / 155.3 g (21%)', color: COL3 },
    { label: 'Lemak', pctg: 46, text: '21.1 g / 46.0 g (46%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 4 },
    { label: 'Zat Besi', pctg: 26 },
    { label: 'Kalsium', pctg: 2 },
    { label: 'Vit. A', pctg: 8 },
    { label: 'Vit. C', pctg: 2 },
    { label: 'Vit. B12', pctg: 17 },
    { label: 'Asam Folat', pctg: 6 },
    { label: 'Potasium', pctg: 7 }
  ]
}

nutriData['STELLAR MEATLOAF'] = {
  description: 'Daging panggang yang penuh dengan microGREENS dan disertai salad kentang dan sayuran. Kaya akan Zat Besi, Vitamin A dan Vitamin B12.',
  total: 501,
  top: [
    { label: 'Energi', pctg: 37, text: '500.5 kcal / 1356 kcal (37%)', color: COL1 },
    { label: 'Protein', pctg: 32, text: '26.9 g / 84.8 g (32%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 26, text: '40.2 g / 152.6 g (26%)', color: COL3 },
    { label: 'Lemak', pctg: 53, text: '24.1 g / 45.2 g (53%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 15 },
    { label: 'Zat Besi', pctg: 55 },
    { label: 'Kalsium', pctg: 6 },
    { label: 'Vit. A', pctg: 188 },
    { label: 'Vit. C', pctg: 22 },
    { label: 'Vit. B12', pctg: 120 },
    { label: 'Asam Folat', pctg: 13 },
    { label: 'Potasium', pctg: 31 }
  ]
}

nutriData['SUPER MAC AND CHEESE'] = {
  description: 'Macaroni dan cheese yang penuh dengan microGREENS, kembang kol dan ayam panggang. Kaya akan Zat Besi, Vitamin C dan Asam Folat.',
  total: 428,
  top: [
    { label: 'Energi', pctg: 32, text: '428.1 kcal / 1356 kcal (32%)', color: COL1 },
    { label: 'Protein', pctg: 32, text: '27.3 g / 84.8 g (32%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 23, text: '35.5 g / 152.6 g (23%)', color: COL3 },
    { label: 'Lemak', pctg: 41, text: '18.6 g / 45.2 g (41%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 11 },
    { label: 'Zat Besi', pctg: 51 },
    { label: 'Kalsium', pctg: 14 },
    { label: 'Vit. A', pctg: 21 },
    { label: 'Vit. C', pctg: 52 },
    { label: 'Vit. B12', pctg: 17 },
    { label: 'Asam Folat', pctg: 53 },
    { label: 'Potasium', pctg: 18 }
  ]
}

nutriData['BRILLIANT BEEF AND BROCCOLI'] = {
  description: 'Daging dan brokoli a la Cina-Amerika yang dilengkapi dengan GREENS Coriander Rice dan microGREENS Broccoli Salad. Kaya akan Zat Besi, Vitamin C dan Vitamin B12.',
  total: 518,
  top: [
    { label: 'Energi', pctg: 38, text: '518.4 kcal / 1380 kcal (38%)', color: COL1 },
    { label: 'Protein', pctg: 44, text: '37.6 g / 86.3 g (44%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 20, text: '30.8 g / 155.3 g (20%)', color: COL3 },
    { label: 'Lemak', pctg: 48, text: '22.2 g / 46.0 g (48%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 10 },
    { label: 'Zat Besi', pctg: 45 },
    { label: 'Kalsium', pctg: 3 },
    { label: 'Vit. A', pctg: 1 },
    { label: 'Vit. C', pctg: 66 },
    { label: 'Vit. B12', pctg: 101 },
    { label: 'Asam Folat', pctg: 26 },
    { label: 'Potasium', pctg: 23 }
  ]
}

nutriData['GREENS RICE OYAKODON'] = {
  description: 'Ayam rebus ala Jepang yang di sertakan dengan GREENS Rice yang penuh nutrisi dan microGREENS. Kaya akan Zat Besi, Vitamin B12 dan Asam Folat.',
  total: 355,
  top: [
    { label: 'Energi', pctg: 26, text: '355.2 kcal / 1356 kcal (26%)', color: COL1 },
    { label: 'Protein', pctg: 28, text: '23.4 g / 84.8 g (28%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 22, text: '33.9 g / 152.6 g (22%)', color: COL3 },
    { label: 'Lemak', pctg: 25, text: '11.4 g / 45.2 g (25%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 6 },
    { label: 'Zat Besi', pctg: 57 },
    { label: 'Kalsium', pctg: 7 },
    { label: 'Vit. A', pctg: 12 },
    { label: 'Vit. C', pctg: 4 },
    { label: 'Vit. B12', pctg: 24 },
    { label: 'Asam Folat', pctg: 24 },
    { label: 'Potasium', pctg: 6 }
  ]
}

nutriData['MARVELOUS MEATBALLS'] = {
  description: 'Bola Daging yang penuh dengan microGREENS, disertai pasta bayam dan selai strawberry. Kaya akan Zat Besi dan Vitamin B12.',
  total: 607,
  top: [
    { label: 'Energi', pctg: 45, text: '607.0 kcal / 1356 kcal (45%)', color: COL1 },
    { label: 'Protein', pctg: 39, text: '33.4 g / 84.8 g (39%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 33, text: '49.8 g / 152.6 g (33%)', color: COL3 },
    { label: 'Lemak', pctg: 60, text: '27.2 g / 45.2 g (60%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 9 },
    { label: 'Zat Besi', pctg: 59 },
    { label: 'Kalsium', pctg: 6 },
    { label: 'Vit. A', pctg: 19 },
    { label: 'Vit. C', pctg: 3 },
    { label: 'Vit. B12', pctg: 139 },
    { label: 'Asam Folat', pctg: 12 },
    { label: 'Potasium', pctg: 17 }
  ]
}

nutriData['SUPER SAUSAGE AND EGG'] = {
  description: 'Sosis panggang dan telur orak arik a la GREENS dengan kentang panggang dan salad microGREENS. Kaya akan Zat Besi, Vitamin A dan Vitamin B12.',
  total: 542,
  top: [
    { label: 'Energi', pctg: 39, text: '542.2 kcal / 1380 kcal (39%)', color: COL1 },
    { label: 'Protein', pctg: 32, text: '27.2 g / 86.3 g (32%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 16, text: '24.8 g / 155.3 g (16%)', color: COL3 },
    { label: 'Lemak', pctg: 77, text: '35.4 g / 46.0 g (77%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 14 },
    { label: 'Zat Besi', pctg: 43 },
    { label: 'Kalsium', pctg: 5 },
    { label: 'Vit. A', pctg: 80 },
    { label: 'Vit. C', pctg: 33 },
    { label: 'Vit. B12', pctg: 52 },
    { label: 'Asam Folat', pctg: 18 },
    { label: 'Potasium', pctg: 30 }
  ]
}

nutriData['SUPREME SALMON TERIYAKI'] = {
  description: 'Salmon panggang saus teriyaki, dengan GREENS Rice dan microGREENS Tamago. Kaya akan Zat Besi dan Vitamin B12.',
  total: 453,
  top: [
    { label: 'Energi', pctg: 33, text: '453.1 kcal / 1356 kcal (33%)', color: COL1 },
    { label: 'Protein', pctg: 42, text: '35.4 g / 84.8 g (42%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 24, text: '36.9 g / 152.6 g (24%)', color: COL3 },
    { label: 'Lemak', pctg: 36, text: '16.4 g / 45.2 g (36%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 7 },
    { label: 'Zat Besi', pctg: 51 },
    { label: 'Kalsium', pctg: 5 },
    { label: 'Vit. A', pctg: 21 },
    { label: 'Vit. C', pctg: 4 },
    { label: 'Vit. B12', pctg: 40 },
    { label: 'Asam Folat', pctg: 18 },
    { label: 'Potasium', pctg: 15 }
  ]
}

nutriData['GREENS RICE WITH CHICKEN KATSU CURRY'] = {
  description: 'Chicken Katsu dengan saus kari yang penuh dengan sayuran dan microGREENS yang juga dilengkapi dengan GREENS Rice. Kaya akan Zat Besi dan Vitamin A.',
  total: 546,
  top: [
    { label: 'Energi', pctg: 40, text: '546.2 kcal / 1380 kcal (40%)', color: COL1 },
    { label: 'Protein', pctg: 46, text: '39.4 g / 86.3 g (46%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 27, text: '41.5 g / 155.3 g (27%)', color: COL3 },
    { label: 'Lemak', pctg: 47, text: '21.7 g / 46.0 g (47%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 14 },
    { label: 'Zat Besi', pctg: 56 },
    { label: 'Kalsium', pctg: 5 },
    { label: 'Vit. A', pctg: 102 },
    { label: 'Vit. C', pctg: 11 },
    { label: 'Vit. B12', pctg: 29 },
    { label: 'Asam Folat', pctg: 14 },
    { label: 'Potasium', pctg: 21 }
  ]
}

nutriData['GREENS PASTA WITH CHICKEN PARM'] = {
  description: 'Pasta homemade a la GREENS yang terbuat dari bayam dan dilengkapi dengan ayam panggang bumbu tomat dan keju mozzarella. Kaya akan Zat Besi, Vitamin A dan Asam Folat.',
  total: 465,
  top: [
    { label: 'Energi', pctg: 34, text: '464.5 kcal / 1380 kcal (34%)', color: COL1 },
    { label: 'Protein', pctg: 41, text: '35.1 g / 86.3 g (41%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 23, text: '35.2 g / 155.3 g (23%)', color: COL3 },
    { label: 'Lemak', pctg: 40, text: '18.3 g / 46.0 g (40%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 9 },
    { label: 'Zat Besi', pctg: 54 },
    { label: 'Kalsium', pctg: 13 },
    { label: 'Vit. A', pctg: 45 },
    { label: 'Vit. C', pctg: 13 },
    { label: 'Vit. B12', pctg: 23 },
    { label: 'Asam Folat', pctg: 50 },
    { label: 'Potasium', pctg: 24 }
  ]
}

nutriData['GREENS PASTA WITH TUNA & KALE'] = {
  description: 'Tuna Panggang dengan pasta yang dilengkapi berbagai microGREENS, telur dan jagung. Kaya akan Zat Besi dan Vitamin B12.',
  total: 599,
  top: [
    { label: 'Energi', pctg: 44, text: '599.1 kcal / 1356 kcal (44%)', color: COL1 },
    { label: 'Protein', pctg: 44, text: '37.3 g / 84.8 g (44%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 22, text: '33.9 g / 152.6 g (22%)', color: COL3 },
    { label: 'Lemak', pctg: 73, text: '32.8 g / 45.2 g (73%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 7 },
    { label: 'Zat Besi', pctg: 64 },
    { label: 'Kalsium', pctg: 9 },
    { label: 'Vit. A', pctg: 23 },
    { label: 'Vit. C', pctg: 3 },
    { label: 'Vit. B12', pctg: 135 },
    { label: 'Asam Folat', pctg: 16 },
    { label: 'Potasium', pctg: 18 }
  ]
}

nutriData['ROBUST ROASTED CHICKEN'] = {
  description: 'Ayam panggang yang dilengkapi dengan GREENS Roasted Potatoes dan Puree Wortel yang penuh dengan microGREENS. Kaya akan Zat Besi dan Vitamin A.',
  total: 333,
  top: [
    { label: 'Energi', pctg: 25, text: '333.4 kcal / 1356 kcal (25%)', color: COL1 },
    { label: 'Protein', pctg: 26, text: '22.2 g / 84.8 g (26%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 14, text: '21.8 g / 152.6 g (14%)', color: COL3 },
    { label: 'Lemak', pctg: 35, text: '15.7 g / 45.2 g (35%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 14 },
    { label: 'Zat Besi', pctg: 53 },
    { label: 'Kalsium', pctg: 4 },
    { label: 'Vit. A', pctg: 536 },
    { label: 'Vit. C', pctg: 36 },
    { label: 'Vit. B12', pctg: 0 },
    { label: 'Asam Folat', pctg: 6 },
    { label: 'Potasium', pctg: 31 }
  ]
}

nutriData['GREENS RICE WITH JAPANESE MEATBALLS'] = {
  description: 'Bola daging dari ayam yang di bakar dan diolesi saus a la Jepang yang disajikan dengan GREENS Rice yang penuh sayuran dan microGREENS. Kaya akan Zat Besi dan Vitamin B12.',
  total: 448,
  top: [
    { label: 'Energi', pctg: 32, text: '448.5 kcal / 1380 kcal (32%)', color: COL1 },
    { label: 'Protein', pctg: 37, text: '31.8 g / 86.3 g (37%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 18, text: '28.1 g / 155.3 g (18%)', color: COL3 },
    { label: 'Lemak', pctg: 46, text: '21.3 g / 46.0 g (46%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 8 },
    { label: 'Zat Besi', pctg: 61 },
    { label: 'Kalsium', pctg: 5 },
    { label: 'Vit. A', pctg: 28 },
    { label: 'Vit. C', pctg: 4 },
    { label: 'Vit. B12', pctg: 83 },
    { label: 'Asam Folat', pctg: 22 },
    { label: 'Potasium', pctg: 30 }
  ]
}

nutriData['GREENS PASTA WITH CHICKEN PESTO'] = {
  description: 'Pasta homemade a la GREENS yang terbuat dari bayam dan dilengkapi dengan ayam panggang bumbu kemangi a la Italia. Kaya akan Zat Besi dan Asam Folat.',
  total: 514,
  top: [
    { label: 'Energi', pctg: 37, text: '513.9 kcal / 1380 kcal (37%)', color: COL1 },
    { label: 'Protein', pctg: 41, text: '35.2 g / 86.3 g (41%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 23, text: '35.9 g / 155.3 g (23%)', color: COL3 },
    { label: 'Lemak', pctg: 51, text: '23.5 g / 46.0 g (51%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 10 },
    { label: 'Zat Besi', pctg: 68 },
    { label: 'Kalsium', pctg: 13 },
    { label: 'Vit. A', pctg: 14 },
    { label: 'Vit. C', pctg: 4 },
    { label: 'Vit. B12', pctg: 22 },
    { label: 'Asam Folat', pctg: 49 },
    { label: 'Potasium', pctg: 22 }
  ]
}

nutriData['GREENS PASTA WITH BASIL AND SAUSAGE'] = {
  description: 'Pasta homemade a la GREENS yang terbuat dari bayam dengan sosis mix dari ayam dan sapi dan juga microGREENS Basil. Kaya akan Lemak tak jenuh dan Vitamin A.',
  total: 563,
  top: [
    { label: 'Energi', pctg: 41, text: '562.6 kcal / 1356 kcal (41%)', color: COL1 },
    { label: 'Protein', pctg: 26, text: '22.3 g / 84.8 g (26%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 31, text: '46.8 g / 152.6 g (31%)', color: COL3 },
    { label: 'Lemak', pctg: 65, text: '29.2 g / 45.2 g (65%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 29 },
    { label: 'Zat Besi', pctg: 30 },
    { label: 'Kalsium', pctg: 8 },
    { label: 'Vit. A', pctg: 24 },
    { label: 'Vit. C', pctg: 14 },
    { label: 'Vit. B12', pctg: 7 },
    { label: 'Asam Folat', pctg: 7 },
    { label: 'Potasium', pctg: 9 }
  ]
}

nutriData['GREENS RICE WITH KOREAN BEEF'] = {
  description: 'GREENS Rice dengan daging sangrai a la Korea yang disajikan dengan telur dan salad microGREENS Spinach. Kaya akan Zat Besi dan Vitamin B12',
  total: 589,
  top: [
    { label: 'Energi', pctg: 43, text: '589.2 kcal / 1380 kcal (43%)', color: COL1 },
    { label: 'Protein', pctg: 45, text: '38.6 g / 86.3 g (45%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 23, text: '36.1 g / 155.3 g (23%)', color: COL3 },
    { label: 'Lemak', pctg: 63, text: '29.1 g / 46.0 g (63%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 13 },
    { label: 'Zat Besi', pctg: 78 },
    { label: 'Kalsium', pctg: 5 },
    { label: 'Vit. A', pctg: 17 },
    { label: 'Vit. C', pctg: 6 },
    { label: 'Vit. B12', pctg: 170 },
    { label: 'Asam Folat', pctg: 21 },
    { label: 'Potasium', pctg: 28 }
  ]
}

nutriData['LIVELY ROASTED SALMON'] = {
  description: 'Salmon panggang dengan GREENS Roasted Potatoes dan krim kacang polong serta saus krim microGREENS. Kaya akan Zat Besi, Vitamin A, Vitamin B12 dan Potassium.',
  total: 481,
  top: [
    { label: 'Energi', pctg: 35, text: '481.2 kcal / 1380 kcal (35%)', color: COL1 },
    { label: 'Protein', pctg: 34, text: '29.2 g / 86.3 g (34%)', color: COL2 },
    { label: 'Karb. Bersih', pctg: 14, text: '21.0 g / 155.3 g (14%)', color: COL3 },
    { label: 'Lemak', pctg: 66, text: '30.0 g / 46.0 g (66%)', color: COL4 },
  ],
  bottom: [
    { label: 'Serat', pctg: 12 },
    { label: 'Zat Besi', pctg: 40 },
    { label: 'Kalsium', pctg: 5 },
    { label: 'Vit. A', pctg: 90 },
    { label: 'Vit. C', pctg: 33 },
    { label: 'Vit. B12', pctg: 443 },
    { label: 'Asam Folat', pctg: 10 },
    { label: 'Potasium', pctg: 43 }
  ]
}

function getNutriData(obj) {
  console.log({ obj });
  const description = obj.description;

  const nfStr = obj.nutritionalFact;
  const labelsStr = obj.nutritionalFactLabels;

  const Labels = (labelsStr && labelsStr.length > 0) ? labelsStr.split(',') : [];
  console.log({ labelsStr, Labels });

  if (!nfStr || nfStr.length < 2) return false;
  const nf = nfStr.split(',');
  // if (nf.length < 13) return false;

  const maxEnergi = Number(nf[0]);
  const data = {
    description: description,
    total: Number(nf[1]),
    top: [
      { label: 'Energi', pctg: 0, color: COL1, text: '' },
      { label: 'Protein', pctg: Number(nf[2]), color: COL2, text: '' },
      { label: 'Karb. Bersih', pctg: Number(nf[3]), color: COL3, text: '' },
      { label: 'Lemak', pctg: Number(nf[4]), color: COL4, text: '' },
    ],
    bottom: [
      { label: 'Serat', pctg: Number(nf[5]) },
      { label: 'Zat Besi', pctg: Number(nf[6]) },
      { label: 'Kalsium', pctg: Number(nf[7]) },
      { label: 'Vit. A', pctg: Number(nf[8]) },
      { label: 'Vit. C', pctg: Number(nf[9]) },
      { label: 'Vit. B12', pctg: Number(nf[10]) },
      { label: 'Asam Folat', pctg: Number(nf[11]) },
      { label: 'Potasium', pctg: Number(nf[12]) }
    ]
  }

  console.log({
    Labels, nf
  });

  if (Labels.length === nf.length) {
    const bottom = [];
    for (let i = 5; i < Labels.length; i++) {
      bottom.push({
        label: Labels[i],
        pctg: Number(nf[i])
      });
    }
    data.bottom = bottom;
  }

  let energiFull = 1380;
  let proteinFull = 86.3;
  let karbFull = 155.3;
  let lemakFull = 46.0;

  if (maxEnergi === 1356) {
    energiFull = 1356;
    proteinFull = 84.8;
    karbFull = 152.6;
    lemakFull = 45.2;
  }

  const energiPctg = Math.round(Number(nf[1]) * 10000 / energiFull) / 100;
  data.top[0].pctg = energiPctg;
  const energi = Math.round(energiPctg * energiFull / 100);
  data.top[0].text = energi + ' kcal / ' + energiFull + ' kcal (' + energiPctg + '%)';

  const proteinPctg = data.top[1].pctg;
  const protein = Math.round(proteinPctg * proteinFull / 100);
  data.top[1].text = protein + ' g / ' + proteinFull + ' g (' + proteinPctg + '%)';

  const karbPctg = data.top[2].pctg;
  const karb = Math.round(karbPctg * karbFull / 100);
  data.top[2].text = karb + ' g / ' + karbFull + ' g (' + karbPctg + '%)';

  const lemakPctg = data.top[3].pctg;
  const lemak = Math.round(lemakPctg * lemakFull / 100);
  data.top[3].text = lemak + ' g / ' + lemakFull + ' g (' + lemakPctg + '%)';
  return data;
}


module.exports = {
  productData: data,
  nutriData,
  getNutriData
};


// parsley, basil, coriander